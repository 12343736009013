import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FamilyMemberProfile, FamilyMemberService } from '@insig-health/services/family-member/family-member.service';
import { PatientProfile, PatientProfileService } from '@insig-health/services/patient-profile/patient-profile.service';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/angular-material.config';
import { HttpErrorResponse } from '@angular/common/http';
import { FormValidatorsService } from '@insig-health/services/form-validators/form-validators.service';
import { RegexService } from '@insig-health/services/regex/regex.service';
import { HtmlConstants } from '@insig-health/constants/html-constants';
import { CpsEsDoctor } from '@insig-health/services/cps-es/cps-es.service';

export type Profile = PatientProfile | FamilyMemberProfile;

@Component({
  selector: 'insig-booking-family-doctor-form',
  templateUrl: './family-doctor-form.component.html',
})
export class FamilyDoctorFormComponent implements OnInit {
  public static readonly DEFAULT_ERROR_MESSAGE = 'An error was encountered while updating the family doctor information';

  private readonly familyMemberService = inject(FamilyMemberService);
  private readonly formValidatorsService = inject(FormValidatorsService);
  private readonly patientProfileService = inject(PatientProfileService);
  private readonly regexService = inject(RegexService);
  private readonly snackBar = inject(MatSnackBar);

  public readonly FAMILY_DOCTOR_FULL_NAME_FORM_CONTROL_NAME = 'familyDoctorFullName';
  public readonly FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME = 'familyDoctorFaxNumber';
  public familyDoctorForm = new FormGroup({
    [this.FAMILY_DOCTOR_FULL_NAME_FORM_CONTROL_NAME]: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    [this.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME]: new FormControl('', { nonNullable: true, validators: [Validators.required, this.formValidatorsService.isPhoneNumberValidValidator(false)] }),
  });

  _patientProfile: Profile | undefined;
  get patientProfile(): Profile | undefined {
    return this._patientProfile;
  }
  @Input() set patientProfile(value: Profile | undefined) {
    this._patientProfile = value;
    if (value) {
      this.autofillFamilyDoctorDetails(value);
    }
  }
  @Input() faxNotesToFamilyDoctor = false;

  @Output() familyDoctorSaved = new EventEmitter();

  public WARNING_GLYPH = HtmlConstants.WARNING_SIGN;

  ngOnInit(): void {
    if (this.patientProfile) {
      this.autofillFamilyDoctorDetails(this.patientProfile);
    }
  }

  handleFamilyDoctorClicked(familyDoctor: CpsEsDoctor): void {
    this.familyDoctorForm.get(this.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME)?.setValue(familyDoctor.faxNumber);
  }

  autofillFamilyDoctorDetails(patientProfile: PatientProfile | FamilyMemberProfile): void {
    this.familyDoctorForm.patchValue({
      [this.FAMILY_DOCTOR_FULL_NAME_FORM_CONTROL_NAME]: patientProfile.familyDoctorFullName ?? '',
      [this.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME]: patientProfile.familyDoctorFaxNumber,
    });
  }

  async handleSaveButtonClicked(familyDoctorForm: FormGroup): Promise<void> {
    if (this.patientProfile === undefined) {
      return;
    }

    try {
      if (this.isPatientProfile(this.patientProfile)) {
        await this.patientProfileService.setCurrentUserPatientProfile({
          ...this.patientProfile,
          familyDoctorFullName: familyDoctorForm.get(this.FAMILY_DOCTOR_FULL_NAME_FORM_CONTROL_NAME)?.value,
          familyDoctorFaxNumber: familyDoctorForm.get(this.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME)?.value,
        });
      } else {
        this.patientProfile.familyDoctorFullName = familyDoctorForm.get(this.FAMILY_DOCTOR_FULL_NAME_FORM_CONTROL_NAME)?.value;
        this.patientProfile.familyDoctorFaxNumber = familyDoctorForm.get(this.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME)?.value;
        await this.familyMemberService.setFamilyMember(this.patientProfile.patientId, this.patientProfile.familyMemberId, this.patientProfile);
      }
      this.familyDoctorSaved.emit();
    } catch (error) {
      console.error(error);
      this.snackBar.open(
        (error as HttpErrorResponse).error?.errorMessage ?? FamilyDoctorFormComponent.DEFAULT_ERROR_MESSAGE,
        undefined,
        { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS },
      );
    }
  }

  isPatientProfile(patientProfile: Profile): patientProfile is PatientProfile {
    return (patientProfile as PatientProfile).uid !== undefined;
  }
}
