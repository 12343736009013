<div class="position-relative font-size-xs fw-bold my-3">
  <div 
    class="p-1 p-md-2 rounded lightGrayBorder labelContainer" 
    [hidden]="expanded"
    (click)="handleTimeSlotContainerClick()"
    role="button"
  >
    <ng-container *ngTemplateOutlet="hourlySlotLabel"></ng-container>
  </div>

  <div
    *ngIf="expanded"
    class="flex-column pb-2 rounded greenContainer"
  >
    <div class="py-2"
      (click)="handleCloseButtonClick()"
      role="button"
    >
      <ng-container *ngTemplateOutlet="hourlySlotLabel"></ng-container>
    </div>
    <hr class="mb-3 mt-0">
    <div
      *ngFor="let slotData of timeSlotData"
      class="mb-3 text-center timeSlot"
      role="button"
      (click)="handleTimeSlotOptionClick(slotData)"
    >
      <span class="notranslate">{{ getAppointmentSlotStartTime(slotData) }}</span>
    </div>
    <div role="button" (click)="handleCloseButtonClick()">
      <img class="d-block mx-auto" src="assets/img/ico-close.svg" alt="close {{ label }} timeslot">
    </div>
  </div>
</div>

<ng-template #hourlySlotLabel>
  <div class="text-center white-space-pre-line white-space-sm-normal">
    <span class="d-inline notranslate">{{ label }}</span>
  </div>
</ng-template>
