import { Component } from '@angular/core';
import { MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { UiComponentsModule } from '@insig-health/components';
import { PROVINCE_SELECTION_DIALOG_CONFIG } from '../../app/choose-doctor/components/province-selection-dialog/province-selection-dialog.component';

@Component({
  standalone: true,
  templateUrl: './is-appropriate-for-virtual-care-dialog.component.html',
  styleUrls: ['./is-appropriate-for-virtual-care-dialog.component.scss'],
  imports: [
    MatDialogModule,
    UiComponentsModule,
  ],
})
export class IsAppropriateForVirtualCareDialogComponent {
  public static readonly DIALOG_ID = 'is-appropriate-for-virtual-care-dialog';
  public static readonly DIALOG_CONFIG: MatDialogConfig = {
    id: IsAppropriateForVirtualCareDialogComponent.DIALOG_ID,
    backdropClass: ['top-mat-dialog-backdrop', 'cdk-overlay-dark-backdrop'],
    panelClass: 'top-mat-dialog',
    maxWidth: PROVINCE_SELECTION_DIALOG_CONFIG.maxWidth,
    width: PROVINCE_SELECTION_DIALOG_CONFIG.width,
  };
}
