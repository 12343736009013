<ng-container [formGroup]="familyDoctorForm">
  <div #containerElement [ngClass]="!(patientProfile | isPatientFamilyDoctorInfoValidPipe) && faxNotesToFamilyDoctor ? 'd-inline' : 'd-none'">
    <span class="bold">Family Doctor</span>
    <div class="d-flex gap-1 py-2">
      <insig-health-family-doctor-search
        isRequired="true"
        [provinceQuery]="patientProfile?.province ?? ''"
        [familyDoctorFullNameControl]="familyDoctorForm.controls[FAMILY_DOCTOR_FULL_NAME_FORM_CONTROL_NAME]"
        (familyDoctorSelected)="handleFamilyDoctorClicked($event)"
      ></insig-health-family-doctor-search>
      <insig-ui-phone-number-input
        insigBookingPhoneNumberInput
        label="Fax Number"
        name="Fax Number"
        [formControlName]="FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME"
        [containerElement]="containerElement"
        [required]="true"
      ></insig-ui-phone-number-input>
    </div>
    <span *ngIf="familyDoctorForm.invalid" class="textPink fw-normal py-2">
      {{ WARNING_GLYPH }} Family doctor information invalid, please update to continue.
    </span>
    <div>
      <insig-ui-button
        [disabled]="familyDoctorForm.invalid"
        iconSrc="assets/img/icon_save_white.svg"
        (click)="handleSaveButtonClicked(familyDoctorForm)"
        (keypress)="handleSaveButtonClicked(familyDoctorForm)"
      >
        Save
      </insig-ui-button>
    </div>
  </div>
</ng-container>
