<a
  class="d-flex text-decoration-none my-2 greenOnHover"
  target="_blank"
  rel="noopener"
  href="https://try.focusmw.com/tiahealth"
  >
  <insig-ui-card class="d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-center">
    <div class="d-flex align-items-start align-items-sm-center align-items-md-start align-items-lg-center my-2 my-sm-0 my-md-2 my-lg-0">
      <img class="mw-33 mw-sm-25 mw-md-33 mw-lg-25" src="assets/img/focus-mental-wellness-logo.png" alt="Focus Mental Wellness" />
      <div class="d-flex d-sm-none d-md-flex d-lg-none vr mx-2"></div>
      <span class="font-size-sm text-black text-start text-sm-center text-md-start text-lg-center px-0 px-sm-2 px-md-0 px-lg-2">Book a licensed Canadian therapist for phone, video, text, or in person therapy. <strong>Starting at $140/hour.</strong></span>
    </div>
    <div class="d-flex w-100 w-sm-33 w-md-100 w-lg-33 justify-content-center my-2">
      <a
        class="w-100 font-size-sm bold text-decoration-none text-center text-white rounded p-2 tiaPrimaryBackgroundColor darkenOnHover"
        role="button"
        target="_blank"
        rel="noopener"
        href="https://try.focusmw.com/tiahealth"
      >Book Now</a>
    </div>
  </insig-ui-card>
</a>
