import { Directive, ElementRef, inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[insigBookingPhoneNumberInputSmallMargins]',
})
export class PhoneNumberInputSmallMarginsDirective implements OnDestroy, OnInit {
  private readonly elementRef = inject(ElementRef);
  private readonly observer = new MutationObserver(this.onMutation.bind(this));
  private readonly onMutated$ = new Subject<void>();

  ngOnInit(): void {
    this.onMutation();
    this.observer.observe(this.elementRef.nativeElement, { childList: true, subtree: true });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  onMutation(): void {
    const phoneInputContainer = this.elementRef.nativeElement.querySelector('.iti') as HTMLElement;
    if (phoneInputContainer) {
      phoneInputContainer.style.width = '100%';
    }

    const inputDivWrappers = this.elementRef.nativeElement.querySelectorAll('#inputDivWrapper') as HTMLElement[];

    inputDivWrappers.forEach((inputDiv) => {
      inputDiv.style.marginTop = '0.25rem';
    });

    const inputElements = this.elementRef.nativeElement.querySelectorAll('input') as HTMLInputElement[];
    inputElements.forEach((inputElement) => {
      inputElement.style.background = 'transparent';
      inputElement.style.border = '2px solid var(--tiaBorder)';
      inputElement.style.width = '100%';
      inputElement.style.minHeight = '48px';
      inputElement.style.borderRadius = '10px';
    });

    this.onMutated$.next();
  }

  getOnMutated(): Observable<void> {
    return this.onMutated$.asObservable();
  }
}
