<form #formElement [formGroup]="pharmacyStandAloneForm" (ngSubmit)="handleSubmit()" class="d-flex flex-column gap-2">
  <div class="d-flex flex-column flex-md-row gap-2">
    <insig-ui-text-input
      label="Name"
      [formControl]="pharmacyStandAloneForm.controls[PHARMACY_NAME_FORM_CONTROL_NAME]"
      [smallMargins]="true"
    ></insig-ui-text-input>
  
    <div>
      <insig-ui-phone-number-input
        insigBookingPhoneNumberInputSmallMargins
        label="Fax Number"
        [formControl]="pharmacyStandAloneForm.controls[PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME]"
        [containerElement]="formElement"
      ></insig-ui-phone-number-input>
      <insig-ui-form-error
        [formControlForErrorMessage]="pharmacyStandAloneForm.controls[PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME]"
        errorText="Pharmacy fax number must be valid"
        errorType="phoneNumberNotValid"
      ></insig-ui-form-error>
    </div>
  </div>

  <insig-ui-button class="w-100" type="submit" [disabled]="pharmacyStandAloneForm.invalid">Update Pharmacy</insig-ui-button>
</form>
