<div>
  <h1>Create your account</h1>
  <p role="doc-subtitle" class="mb-3">Enter your account details to continue</p>
  <form #formElement [formGroup]="patientRegistrationForm">
    <insig-ui-card class="d-flex flex-column flex-lg-row w-100 p-4 gap-4">
      <ng-content *ngTemplateOutlet="patientDetailsForm"></ng-content>
      <ng-container *ngIf="isPharmacyDetailsFormVisible">
        <hr class="d-none d-lg-block my-0">
        <ng-content *ngTemplateOutlet="pharmacyForm"></ng-content>
      </ng-container>
    </insig-ui-card>

    <ng-template #patientDetailsForm>
      <ng-container [formGroupName]="SIGN_UP_DETAILS_FORM_NAME">
        <div class="d-flex flex-column gap-4">
          <div class="d-grid grid-cols-4 gap-2">
            <div class="d-flex flex-column grid-cols-span-3">
              <insig-ui-text-input
                #addressInput
                label="Address"
                [formControlName]="ADDRESS_FORM_CONTROL_NAME"
                name="street-address"
                isRequired="true"
              >
              </insig-ui-text-input>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[ADDRESS_FORM_CONTROL_NAME]"
                errorText="Address is required."
                errorType="required"
              >
              </insig-ui-form-error>
            </div>
  
            <div class="d-flex flex-column grid-cols-span-1">
              <insig-ui-select label="Province" isRequired="true" [formControlName]="PROVINCE_FORM_CONTROL_NAME">
                <option
                  *ngFor="let healthCardProvince of healthCardProvinces"
                  class="notranslate bold"
                  [value]="healthCardProvince"
                >
                  {{ healthCardProvince }}
                </option>
              </insig-ui-select>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[PROVINCE_FORM_CONTROL_NAME]"
                errorText="Province is required."
                errorType="required"
              >
              </insig-ui-form-error>
            </div>
  
            <div
              class="d-flex flex-column grid-cols-span-2"
              [ngClass]="{
                'grid-cols-span-md-1': !isPharmacyDetailsFormVisible,
                'grid-cols-span-md-2': isPharmacyDetailsFormVisible,
                }">
              <insig-ui-text-input
                label="City"
                [formControlName]="CITY_FORM_CONTROL_NAME"
                name="city"
                isRequired="true"
              >
              </insig-ui-text-input>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[CITY_FORM_CONTROL_NAME]"
                errorText="City is required."
                errorType="required"
              >
              </insig-ui-form-error>
            </div>
  
            <div
              class="d-flex flex-column grid-cols-span-2"
              [ngClass]="{
                'grid-cols-span-md-1': !isPharmacyDetailsFormVisible,
                'grid-cols-span-md-2': isPharmacyDetailsFormVisible,
                }">
              <insig-ui-select label="Country" isRequired="true" [formControlName]="COUNTRY_FORM_CONTROL_NAME">
                <option value="Canada" class="bold">Canada</option>
                <option value="United States of America" class="bold">United States of America</option>
              </insig-ui-select>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[COUNTRY_FORM_CONTROL_NAME]"
                errorText="Country is required."
                errorType="required"
              >
              </insig-ui-form-error>
            </div>
  
            <div
              class="d-flex flex-column grid-cols-span-2"
              [ngClass]="{
                'grid-cols-span-md-1': !isPharmacyDetailsFormVisible,
                'grid-cols-span-md-2': isPharmacyDetailsFormVisible,
                }">
              <insig-ui-text-input
                label="Zip/Postal Code"
                [formControlName]="POSTAL_CODE_FORM_CONTROL_NAME"
                name="postal-code"
                isRequired="true"
              >
              </insig-ui-text-input>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[POSTAL_CODE_FORM_CONTROL_NAME]"
                errorText="Postal Code is required."
                errorType="required"
              >
              </insig-ui-form-error>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[POSTAL_CODE_FORM_CONTROL_NAME]"
                errorText="Postal Code must be valid."
                errorType="postalCodeNotValid"
              >
              </insig-ui-form-error>
            </div>
  
            <div
              class="d-flex flex-column grid-cols-span-2"
              [ngClass]="{
                'grid-cols-span-md-1': !isPharmacyDetailsFormVisible,
                'grid-cols-span-md-2': isPharmacyDetailsFormVisible,
                }">
              <insig-ui-date-picker label="Date of Birth" isDisabled="false" [formControlName]="DATE_OF_BIRTH_FORM_CONTROL_NAME" [maxDate]="maximumBirthdate" isRequired="true">
              </insig-ui-date-picker>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[DATE_OF_BIRTH_FORM_CONTROL_NAME]"
                errorText="Date of Birth is required."
                errorType="required"
              >
              </insig-ui-form-error>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[DATE_OF_BIRTH_FORM_CONTROL_NAME]"
                errorText="Date of Birth must be a valid date."
                errorType="birthdateIsNotValid"
              >
              </insig-ui-form-error>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[DATE_OF_BIRTH_FORM_CONTROL_NAME]"
                errorText="Date of Birth must be in the format YYYY-MM-DD."
                errorType="birthdateNotInCorrectFormat"
              >
              </insig-ui-form-error>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[DATE_OF_BIRTH_FORM_CONTROL_NAME]"
                errorText="You must be older to register."
                errorType="dateIsAfterMaximumDate"
              >
              </insig-ui-form-error>
            </div>
  
            <div
              class="d-flex flex-column grid-cols-span-2"
              [ngClass]="{
                'grid-cols-span-md-1': !isPharmacyDetailsFormVisible,
                'grid-cols-span-md-2': isPharmacyDetailsFormVisible,
                }">
              <insig-ui-select label="Designated Gender at Birth" isRequired="true" [formControlName]="GENDER_FORM_CONTROL_NAME">
                <option value="">Select</option>
                <option
                  *ngFor="let gender of genders"
                  class="bold"
                  [value]="gender"
                >
                  {{ gender }}
                </option>
              </insig-ui-select>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[GENDER_FORM_CONTROL_NAME]"
                errorText="Gender is required."
                errorType="required"
              ></insig-ui-form-error>
            </div>
  
            <div
              class="d-flex flex-column grid-cols-span-2 justify-content-end"
              [ngClass]="{
                'grid-cols-span-md-1': !isPharmacyDetailsFormVisible,
                'grid-cols-span-md-2': isPharmacyDetailsFormVisible,
                }">
              <insig-ui-text-input
                label="Health Card"
                [formControlName]="HEALTH_CARD_NUMBER_FORM_CONTROL_NAME"
                isRequired="false"
              >
              </insig-ui-text-input>
            </div>
  
            <div
              class="d-flex flex-column grid-cols-span-2 justify-content-end"
              [ngClass]="{
                'grid-cols-span-md-1': !isPharmacyDetailsFormVisible,
                'grid-cols-span-md-2': isPharmacyDetailsFormVisible,
                }">
              <insig-health-family-doctor-search
                [provinceControl]="signUpDetailsForm.controls[PROVINCE_FORM_CONTROL_NAME]"
                [familyDoctorFullNameControl]="signUpDetailsForm.controls[FAMILY_DOCTOR_FULL_NAME_FORM_CONTROL_NAME]"
                (familyDoctorSelected)="handleFamilyDoctorClicked($event)"
              ></insig-health-family-doctor-search>
            </div>
  
            <div
              class="d-flex flex-column grid-cols-span-2 justify-content-end"
              [ngClass]="{
                'grid-cols-span-md-1': !isPharmacyDetailsFormVisible,
                'grid-cols-span-md-2': isPharmacyDetailsFormVisible,
                }">
              <insig-ui-phone-number-input
                insigBookingPhoneNumberInput
                label="Family Doctor Fax Number"
                [formControlName]="FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME"
                [containerElement]="formElement"
              ></insig-ui-phone-number-input>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME]"
                errorText="Not a valid fax number."
                errorType="phoneNumberNotValid"
              >
              </insig-ui-form-error>
            </div>
          </div>
          <div class="w-100" [ngClass]="{
            'w-lg-75': !isPharmacyDetailsFormVisible,
          }">
            <div role="radiogroup" class="d-flex flex-column gap-3 border-0 px-0">
              <span class="bold textSecondary">How do you want to receive your medications?<span class="textPink">*</span></span>
              <div class="d-flex flex-column flex-lg-row gap-2">
                <div class="d-flex align-items-stretch w-100 w-lg-50 rounded justify-content-between"
                  [ngClass]="{
                    'grayBorder': signUpDetailsForm.controls[PRESCRIPTION_DELIVERY_METHOD_FORM_CONTROL_NAME]?.value !== PrescriptionDeliveryMethod.PILLWAY,
                    'greenBorder': signUpDetailsForm.controls[PRESCRIPTION_DELIVERY_METHOD_FORM_CONTROL_NAME]?.value === PrescriptionDeliveryMethod.PILLWAY,
                  }"
                >
                  <label 
                    class="d-flex rounded p-2">
                    <div class="d-flex px-2 px-lg-3">
                      <input
                        type="radio"
                        [name]="PRESCRIPTION_DELIVERY_METHOD_FORM_CONTROL_NAME"
                        [value]="PrescriptionDeliveryMethod.PILLWAY"
                        [formControlName]="PRESCRIPTION_DELIVERY_METHOD_FORM_CONTROL_NAME"
                      >
                      <img class="my-auto" width="30px" [src]="signUpDetailsForm.controls[PRESCRIPTION_DELIVERY_METHOD_FORM_CONTROL_NAME]?.value === PrescriptionDeliveryMethod.PILLWAY ? 'assets/img/ico-checked-outlined.svg' : 'assets/img/ico-unchecked.svg'" alt="">
                    </div>
                    <div class="d-flex flex-column">
                      <strong class="font-size-lg textBlue">Prescription Delivery</strong>
                      <strong class="text-break font-size-sm textSecondary"
                      >
                        Free delivery to your door, as fast as same day.
                      </strong>
                    </div>
                  </label>

                  <button
                    type="button"
                    class="p-2 font-size-sm bold text-nowrap textBlue"
                    (click)="handlePillwayLearnMoreClicked()"
                  >
                    Learn more
                  </button>
                </div>
    
                <label 
                  class="d-flex align-items-center rounded w-100 w-lg-50 my-2 my-md-0 px-2 py-1 p-md-2"
                  [ngClass]="{
                    'grayBorder': !isPharmacyDetailsFormVisible,
                    'greenBorder': isPharmacyDetailsFormVisible,
                  }"
                >
                  <div class="d-flex px-2 px-lg-3">
                    <input
                      type="radio"
                      [name]="PRESCRIPTION_DELIVERY_METHOD_FORM_CONTROL_NAME"
                      [value]="PrescriptionDeliveryMethod.PHARMACY"
                      [formControlName]="PRESCRIPTION_DELIVERY_METHOD_FORM_CONTROL_NAME"
                    >
                    <img class="my-auto" width="30px" [src]="isPharmacyDetailsFormVisible ? 'assets/img/ico-checked-outlined.svg' : 'assets/img/ico-unchecked.svg'" alt="">
                  </div>
                  <strong class="font-size-sm textSecondary">Pickup at an external pharmacy</strong>
                </label>
              </div>
            </div>
          </div>
          <div class="d-block" [ngClass]="{ 'd-none': isPharmacyDetailsFormVisible }">
            <ng-content *ngTemplateOutlet="finishSignUpButton"></ng-content>
          </div>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #pharmacyForm>
      <div class="d-flex flex-column flex-fill mt-4 mt-lg-0">
        <insig-booking-pharmacy-map
          [pharmacies]="pharmacies"
          [selectedPharmacy]="selectedPharmacy"
          (pharmacyMarkerClicked)="handlePharmacyMarkerClicked($event)"
        ></insig-booking-pharmacy-map>
  
        <ng-container [formGroupName]="PHARMACY_DETAILS_FORM_NAME">
          <div class="d-flex flex-column mb-2">
            <div class="w-100">
              <insig-ui-text-input
                class="py-2"
                label="Pharmacy Name"
                [formControlName]="PHARMACY_NAME_FORM_CONTROL_NAME"
                isRequired="true"
              >
              </insig-ui-text-input>
              <insig-ui-form-error
                [formControlForErrorMessage]="pharmacyDetailsForm.controls[PHARMACY_NAME_FORM_CONTROL_NAME]"
                errorText="Pharmacy Name is required."
                errorType="required"
              >
              </insig-ui-form-error>
            </div>
            <div class="w-100">
              <insig-ui-phone-number-input
                insigBookingPhoneNumberInput
                class="py-2"
                label="Pharmacy Fax Number"
                [formControlName]="PHARMACY_FAX_FORM_CONTROL_NAME"
                [containerElement]="formElement"
                [required]="true"
              ></insig-ui-phone-number-input>
              <insig-ui-form-error
                [formControlForErrorMessage]="pharmacyDetailsForm.controls[PHARMACY_FAX_FORM_CONTROL_NAME]"
                errorText="Pharmacy Fax is required."
                errorType="required"
              >
              </insig-ui-form-error>
              <insig-ui-form-error
                [formControlForErrorMessage]="pharmacyDetailsForm.controls[PHARMACY_FAX_FORM_CONTROL_NAME]"
                errorText="Pharmacy Fax is not a valid phone number."
                errorType="phoneNumberNotValid"
              >
              </insig-ui-form-error>
            </div>
          </div>
        </ng-container>

        <ng-content *ngTemplateOutlet="pharmacyListDesktop"></ng-content>
        <ng-content *ngTemplateOutlet="pharmacyListMobile"></ng-content>

        <ng-content *ngTemplateOutlet="finishSignUpButton"></ng-content>
      </div>
    </ng-template>

    <ng-template #pharmacyListDesktop>
      <div *ngIf="pharmacies.length > 0" class="d-none d-lg-flex flex-column max-lg-height-325px">
        <span class="h2">Pharmacies near you</span>
        <hr>
        <div class="overflow-auto">
          <ng-content *ngTemplateOutlet="pharmacyList"></ng-content>
        </div>
      </div>
    </ng-template>

    <ng-template #pharmacyListMobile>
      <div *ngIf="pharmacies.length > 0" class="d-flex d-lg-none">

        <insig-ui-expansion-panel class="w-100">
          <div header class="bold">
            <ng-container *ngIf="selectedPharmacy !== undefined; else choosePharmacyText">
              <span>Selected pharmacy: {{ selectedPharmacy.name }}</span>
            </ng-container>
            <ng-template #choosePharmacyText>
              <span>Choose a pharmacy</span>
            </ng-template>
          </div>
          <div content>
            <ng-content *ngTemplateOutlet="pharmacyList"></ng-content>
          </div>
        </insig-ui-expansion-panel>
      </div>
    </ng-template>

    <ng-template #pharmacyList>
      <div class="d-flex flex-column px-2">
        <ng-container *ngFor="let pharmacy of pharmacies">
          <label 
            class="d-flex rounded w-100 p-2 mb-3 justify-content-between grayBorder"
            [ngClass]="{ 'selected': pharmacy.id === selectedPharmacy?.id }"
          >
            <div class="d-flex flex-column">
              <div class="bold text-break">{{ pharmacy.name }}</div>
              <div class="textSecondary">{{ pharmacy.address }}</div>
            </div>
            <input
              class="ms-auto"
              type="radio"
              name="pharmacies"
              [value]="pharmacy.id"
              (click)="handlePharmacyRadioClicked(pharmacy)"
            >
            <img [src]="pharmacy.id === selectedPharmacy?.id ? 'assets/img/ico-checked-outlined.svg' : 'assets/img/ico-unchecked.svg'" alt="">
          </label>
        </ng-container>
      </div>
    </ng-template>

    <ng-template #finishSignUpButton>
      <ng-container *ngIf="showSpinner; else registerButton">
        <mat-progress-spinner
          class="d-flex align-items-start"
          mode="indeterminate"
          [diameter]="25"
        >
        </mat-progress-spinner>
      </ng-container>
      <ng-template #registerButton>
        <div class="d-flex justify-content-center justify-content-md-end mt-2">
          <insig-ui-button
            [disabled]="patientRegistrationForm.invalid"
            iconSrc="assets/img/ico-signup-wh.svg"
            (click)="handleRegisterButtonClick()"
          >
            Finish Sign Up
          </insig-ui-button>
        </div>
      </ng-template>
    </ng-template>
  </form>
</div>
