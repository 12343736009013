<div mat-dialog-title>
  Please Note
</div>

<mat-dialog-content>
  <p>
    Tia is not appropriate for emergencies.
    <strong>If this is an emergency, please visit your nearest emergency centre.</strong>
  </p>

  <p>
    Additionally, Tia is not appropriate for the following conditions:
  </p>

  <ul>
    <li>Chest pain, palpitations, or shortness of breath at rest</li>
    <li>Loss of consciousness</li>
    <li>Head injury</li>
    <li>Young children with serious symptoms (e.g. high or prolonged fever, lethargy)</li>
    <li>Controlled substances</li>
    <li>Vehicle accidents</li>
    <li>Workplace injuries</li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <insig-ui-button mat-dialog-close>Continue</insig-ui-button>
</mat-dialog-actions>
