import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChooseDoctorComponent } from './choose-doctor/choose-doctor.component';
import { ChooseTimeComponent } from './choose-time/choose-time.component';
import { ClinicSelectionComponent } from './clinic-selection/clinic-selection.component';
import { ConfirmBookingComponent } from './confirm-booking/confirm-booking.component';
import { QuickConnectComponent } from './quick-connect/quick-connect.component';
import { StripePaymentRedirectComponent } from './stripe-payment-redirect-page/stripe-payment-redirect.component';
import { SuccessfulBookingComponent } from './successful-booking/successful-booking.component';
import { YourDetailsComponent } from './your-details/your-details.component';

import { TimeslotReservationGuard } from '../guards/timeslot-reservation/timeslot-reservation.guard';
import { CompanyBookingComponent } from './company-booking/company-booking.component';
import { DoctorBookingComponent } from './doctor-booking/doctor-booking.component';
import { ChooseTimeLoginComponent } from './choose-time-login/choose-time-login.component';
import { ChooseDoctorLoginComponent } from './choose-doctor-login/choose-doctor-login.component';
import { DraftBookingComponent } from './draft-booking/draft-booking.component';
import { ProvinceBookingComponent } from './province-booking/province-booking.component';
import { BillingTypeBookingComponent } from './billing-type-booking/billing-type-booking.component';
import { environment } from '@insig-health/config/environment';
import { BillingType } from '../services/billing-type/billing-type.service';
import { ProvincePublicAppointmentsGuard } from '../guards/province-public-appointments/province-public-appointments.guard';
import { DefaultProvinceRedirectComponent } from './default-province-redirect-component/default-province-redirect.component';
import { IsProfileCompleteGuard } from '../guards/is-profile-complete/is-profile-complete.guard';
import { IsDraftValidGuard } from '../guards/is-draft-valid/is-draft-valid.guard';
import { InternationalBookingComponent } from './international-booking/international-booking.component';
import { ThemeValidBillingRegionsGuard } from '../guards/theme-valid-billing-regions/theme-valid-billing-regions.guard';
import { isAppropriateForVirtualCareGuard } from '../guards/is-appropriate-for-virtual-care/is-appropriate-for-virtual-care.guard';

const defaultCompanyId = environment.production ? 'tiaHealth' : 'XEFJA8FoDY3d7vqryzQdNl3nLnmzLcp6';
const defaultBillingType = BillingType.PUBLIC;

export const appRoutes: Routes = [
  {
    path: 'company/:companyId',
    component: CompanyBookingComponent,
    canActivate: [isAppropriateForVirtualCareGuard],
    children: [
      {
        path: 'province/:provinceAbbreviation',
        component: ProvinceBookingComponent,
        children: [
          {
            path: 'billing/:billingType',
            component: BillingTypeBookingComponent,
            canActivate: [ThemeValidBillingRegionsGuard, ProvincePublicAppointmentsGuard],
            children: [
              { path: 'chooseDoctor/login', component: ChooseDoctorLoginComponent },
              { path: 'chooseDoctor', component: ChooseDoctorComponent },
              {
                path: 'doctor/:doctorId',
                component: DoctorBookingComponent,
                children: [
                  { path: 'service/:serviceId/chooseTime/login', component: ChooseTimeLoginComponent },
                  { path: 'service/:serviceId/chooseTime', component: ChooseTimeComponent },
                  { path: '**', redirectTo: 'service/undefined/chooseTime', pathMatch: 'full' },
                ],
              },
              { path: '**', redirectTo: 'chooseDoctor', pathMatch: 'full' },
            ],
          },
          { path: '**', redirectTo: `billing/${defaultBillingType}`, pathMatch: 'full' },
        ],
      },
      {
        path: 'international',
        component: InternationalBookingComponent,
        canActivate: [ThemeValidBillingRegionsGuard],
        children: [
          { path: 'billing/public', redirectTo: 'billing/private' },
          {
            path: 'billing/:billingType',
            component: BillingTypeBookingComponent,
            children: [
              { path: 'chooseDoctor/login', component: ChooseDoctorLoginComponent },
              { path: 'chooseDoctor', component: ChooseDoctorComponent },
              {
                path: 'doctor/:doctorId',
                component: DoctorBookingComponent,
                children: [
                  { path: 'service/:serviceId/chooseTime/login', component: ChooseTimeLoginComponent },
                  { path: 'service/:serviceId/chooseTime', component: ChooseTimeComponent },
                  { path: '**', redirectTo: 'service/undefined/chooseTime', pathMatch: 'full' },
                ],
              },
              { path: '**', redirectTo: 'chooseDoctor', pathMatch: 'full' },
            ],
          },
          { path: '**', redirectTo: 'billing/private', pathMatch: 'full' },
        ],
      },
      {
        path: 'draft/:draftAppointmentId',
        component: DraftBookingComponent,
        children: [
          { path: 'yourDetails', component: YourDetailsComponent, canDeactivate: [TimeslotReservationGuard] },
          {
            path: 'confirmBooking', component: ConfirmBookingComponent,
            canActivate: [IsProfileCompleteGuard, IsDraftValidGuard],
            canDeactivate: [TimeslotReservationGuard],
          },
        ],
      },
      { path: 'doctorId/:doctorId/appointmentMedium/:appointmentMedium/serviceId/:serviceId/serviceType/:serviceType/startTime/:startTime/success', component: SuccessfulBookingComponent },
      { path: 'quickConnect', component: QuickConnectComponent },
      { path: 'inclinic', component: ClinicSelectionComponent },
      { path: 'stripeRedirect', component: StripePaymentRedirectComponent },
      { path: '**', component: DefaultProvinceRedirectComponent },
    ],
  },
  { path: '**', redirectTo: `company/${defaultCompanyId}`, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { canceledNavigationResolution: 'computed' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
