import { Injectable, OnDestroy, Type, inject } from '@angular/core';
import { Router, RouterEvent, NavigationEnd, NavigationExtras, ActivatedRoute, ActivatedRouteSnapshot, createUrlTreeFromSnapshot, UrlTree } from '@angular/router';
import { ChooseDoctorLoginComponent } from '../../app/choose-doctor-login/choose-doctor-login.component';
import { ChooseTimeLoginComponent } from '../../app/choose-time-login/choose-time-login.component';
import { Observable, Subscription } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { Province } from '@insig-health/services/province/province.service';
import { AppointmentMedium } from '../appointment-medium/appointment-medium.service';
import { JumpToStepQueue } from './jump-to-step-queue';
import { BillingRegion, RegionType } from '../billing-region/billing-region.service';

export enum BookingStep {
  CHOOSE_DOCTOR = 'choose_doctor_step',
  CHOOSE_TIME = 'choose_time_step',
  LOGIN = 'login_step',
  CONFIRM_BOOKING = 'confirm_booking_step',
  SUCCESS = 'successful_booking_step',
  QUICK_CONNECT = 'quick_connect_step',
}

export interface JumpToStepOptions {
  navigationExtras?: NavigationExtras;
  pathParams?: Record<string, string | BillingRegion>;
  queryParams?: Record<string, string>;
}

export type JumpToChooseDoctorStepOptions = JumpToStepOptions & {
  pathParams: {
    region: BillingRegion;
  };
}

export type JumpToChooseTimeStepOptions = JumpToStepOptions & ChooseTimeStepOptions;

export type JumpToYourDetailsStepOptions = JumpToStepOptions & YourDetailsStepOptions;

export type JumpToConfirmBookingStepOptions = JumpToStepOptions & {
  pathParams: {
    draftAppointmentId: string;
  };
}

export type JumpToSuccessStepOptions = JumpToStepOptions & {
  pathParams: {
    doctorId: string;
    appointmentMedium: AppointmentMedium;
    serviceId: string;
    serviceType: string;
    startTime: string;
  }
}

export type GetUrlTreeForChooseTimeStepOptions = GetUrlTreeForStepOptions & ChooseTimeStepOptions;
export type GetUrlTreeForLoginStepOptions = GetUrlTreeForStepOptions & YourDetailsStepOptions;

type GetUrlTreeForStepOptions = {
  relativeTo: ActivatedRouteSnapshot;
}

type ChooseTimeStepOptions = {
  pathParams: {
    region: BillingRegion;
    doctorId: string;
    serviceId: string;
  };
  queryParams?: {
    redirectedFromLogout?: string;
  }
}

type YourDetailsStepOptions = {
  pathParams: {
    draftAppointmentId: string;
  };
}

const CHOOSE_DOCTOR_STEP_REGEX = /\/chooseDoctor/;
const CHOOSE_TIME_STEP_REGEX = /\/chooseTime/;
const LOGIN_STEP_REGEX = /\/yourDetails/;
const CONFIRM_BOOKING_STEP_REGEX = /\/confirmBooking/;
const SUCCESS_STEP_REGEX = /\/success/;
const QUICK_CONNECT_REGEX = /\/quickConnect/;

@Injectable({
  providedIn: 'root',
})
export class BookingStepService implements OnDestroy {
  private readonly router = inject(Router);
  private readonly analyticsService = inject(AnalyticsService);
  private _currentStep = BookingStep.CHOOSE_DOCTOR;
  private _jumpToStepQueue = new JumpToStepQueue(this.router);

  private bookingStepChangedSubscription: Subscription | undefined;
  public bookingStepChange$: Observable<BookingStep>;

  constructor() {
    this.bookingStepChange$ = (this.router.events as Observable<RouterEvent>).pipe(
      filter(this.isNavigationEnd),
      map((navigationEndRouterEvent) => this.getBookingStepFromRouterEvent(navigationEndRouterEvent)),
      filter((bookingStep): bookingStep is BookingStep => bookingStep !== undefined),
      shareReplay(1),
    );

    this.bookingStepChangedSubscription = this.bookingStepChange$.subscribe((bookingStep) => {
      this._currentStep = bookingStep;
      this.analyticsService.logEvent(this._currentStep);
    });
  }

  ngOnDestroy(): void {
    this.bookingStepChangedSubscription?.unsubscribe();
  }

  isNavigationEnd(routerEvent: RouterEvent): routerEvent is NavigationEnd {
    return routerEvent instanceof NavigationEnd;
  }

  getBookingStepFromRouterEvent(routerEvent: RouterEvent): BookingStep | undefined {
    const url = (routerEvent as NavigationEnd).urlAfterRedirects;
    try {
      return this.getStepFromUrl(url);
    } catch (error) {
      return undefined;
    }
  }

  getStepFromUrl(url: string): BookingStep {
    if (CHOOSE_DOCTOR_STEP_REGEX.test(url)) {
      return BookingStep.CHOOSE_DOCTOR;
    }

    if (CHOOSE_TIME_STEP_REGEX.test(url)) {
      return BookingStep.CHOOSE_TIME;
    }

    if (LOGIN_STEP_REGEX.test(url)) {
      return BookingStep.LOGIN;
    }

    if (CONFIRM_BOOKING_STEP_REGEX.test(url)) {
      return BookingStep.CONFIRM_BOOKING;
    }

    if (SUCCESS_STEP_REGEX.test(url)) {
      return BookingStep.SUCCESS;
    }

    if (QUICK_CONNECT_REGEX.test(url)) {
      return BookingStep.QUICK_CONNECT;
    }

    throw new Error(`URL did not match any known steps: ${url}`);
  }

  getCurrentStep(): BookingStep {
    return this._currentStep;
  }

  getActivatedRouteOfComponentType<R extends ActivatedRoute | ActivatedRouteSnapshot, T>(activatedRoute: R, componentType: Type<T>): R {
    const deepestActivatedRouteChild = this.getDeepestActivatedRouteChild(activatedRoute);
    return this.getActivatedRouteAncestorOfComponentType(deepestActivatedRouteChild, [componentType]);
  }

  getActivatedRouteAncestorOfComponentType<R extends ActivatedRoute | ActivatedRouteSnapshot, T>(activatedRoute: R, componentTypes: Type<T>[]): R {
    while (!componentTypes.find((componentType) => componentType === activatedRoute.component)) {
      if (activatedRoute.parent === null) {
        throw new Error(`No route ancestor for component of type(s) ${componentTypes}`);
      } else {
        activatedRoute = activatedRoute.parent as R;
      }
    }
    return activatedRoute;
  }

  hasActivatedRouteAncestorOfComponentType<T>(activatedRoute: ActivatedRoute, componentType: Type<T>): boolean {
    while (activatedRoute.component !== componentType) {
      if (activatedRoute.parent === null) {
        return false;
      } else {
        activatedRoute = activatedRoute.parent;
      }
    }
    return true;
  }

  async jumpToStep(bookingStep: BookingStep.CHOOSE_DOCTOR, options?: JumpToChooseDoctorStepOptions): Promise<boolean>;
  async jumpToStep(bookingStep: BookingStep.CHOOSE_TIME, options: JumpToChooseTimeStepOptions): Promise<boolean>;
  async jumpToStep(bookingStep: BookingStep.LOGIN, options: JumpToYourDetailsStepOptions): Promise<boolean>;
  async jumpToStep(bookingStep: BookingStep.CONFIRM_BOOKING, options: JumpToConfirmBookingStepOptions): Promise<boolean>;
  async jumpToStep(bookingStep: BookingStep.SUCCESS, options: JumpToSuccessStepOptions): Promise<boolean>;
  async jumpToStep(bookingStep: BookingStep, options?: JumpToStepOptions): Promise<boolean> {
    return this._jumpToStepQueue.enqueue({ bookingStep, options });
  }

  isLoginAvailable(route: ActivatedRoute): boolean {
    const deepestRoute = this.getDeepestActivatedRouteChild(route);
    const isChooseDoctorLoginComponentActive = this.hasActivatedRouteAncestorOfComponentType(deepestRoute, ChooseDoctorLoginComponent);
    const isChooseTimeLoginComponentActive = this.hasActivatedRouteAncestorOfComponentType(deepestRoute, ChooseTimeLoginComponent);
    return (this._currentStep === BookingStep.CHOOSE_DOCTOR || this._currentStep === BookingStep.CHOOSE_TIME) && !isChooseDoctorLoginComponentActive && ! isChooseTimeLoginComponentActive;
  }

  getDeepestActivatedRouteChild<T extends ActivatedRoute | ActivatedRouteSnapshot>(route: T): T {
    while (route.firstChild !== null) {
      route = route.firstChild as T;
    }
    return route;
  }

  getUrlTreeForChooseTimeStep(options: GetUrlTreeForChooseTimeStepOptions): UrlTree {
    let province: Province | string = Province.ON;
    if (options.pathParams.region.type === RegionType.PROVINCE) {
      province = options.pathParams.region.provinceAbbreviation;
    }

    return createUrlTreeFromSnapshot(options.relativeTo, [
      'province', province,
      'billing', options.pathParams.region.billingType,
      'doctor', options.pathParams.doctorId,
      'service', options.pathParams.serviceId,
      'chooseTime',
    ], options.relativeTo.queryParams);
  }

  getUrlTreeForLoginStep(options: GetUrlTreeForLoginStepOptions): UrlTree {
    return createUrlTreeFromSnapshot(options.relativeTo, [
      'draft', options.pathParams.draftAppointmentId,
      'yourDetails',
    ], options.relativeTo.queryParams);
  }
}
