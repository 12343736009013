import { inject, Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormValidatorsService } from '@insig-health/services/form-validators/form-validators.service';
import { Profile } from './family-doctor-form.component';

@Pipe({
  name: 'isPatientFamilyDoctorInfoValidPipe',
  standalone: true,
})
export class PatientFamilyDoctorInfoValidPipe implements PipeTransform {
  private readonly formValidatorsService = inject(FormValidatorsService);

  transform(patientProfile: Profile | undefined): boolean {
    return patientProfile !== undefined &&
      patientProfile.familyDoctorFullName !== '' &&
      patientProfile.familyDoctorFaxNumber !== '' &&
      this.formValidatorsService.isPhoneNumberValidValidator(false)(new FormControl(patientProfile.familyDoctorFaxNumber)) === null;
  }
}
