import { Component, EventEmitter, Output, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PatientRegistrationV2 } from '@insig-health/api/patient-registration-api';
import { PatientRegistrationService } from '@insig-health/services/patient-registration/patient-registration.service';
import { FormValidatorsService } from '@insig-health/services/form-validators/form-validators.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { NewTiaPatientTermsDialogComponent } from '../../terms-and-conditions/patient-terms-and-conditions/new-tia-patient-terms-dialog.component';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/angular-material.config';
import { firstValueFrom } from 'rxjs';
import { PatientTermsOfUseComponent } from '@insig-health/patient-terms-of-use/patient-terms-of-use.component';
import { AwsWafCaptchaDialogComponent } from '@insig-health/services/aws-waf-captcha/aws-waf-captcha-dialog.component';
import { CompanyBookingComponent } from '../../company-booking/company-booking.component';
import { BookingStepService } from 'apps/insig-booking/src/services/booking-step/booking-step.service';
import { ActivatedRoute } from '@angular/router';
import { TIA_HEALTH_COMPANY_ID } from 'apps/insig-booking/src/constants';

@Component({
  selector: 'insig-booking-patient-sign-up',
  templateUrl: './patient-sign-up.component.html',
})
export class PatientSignUpComponent implements OnInit {
  private readonly patientRegistrationService = inject(PatientRegistrationService);
  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly formValidatorsService = inject(FormValidatorsService);
  private readonly snackBar = inject(MatSnackBar);
  private readonly dialog = inject(MatDialog);
  private readonly bookingStepService = inject(BookingStepService);
  private readonly activatedRoute = inject(ActivatedRoute);
  public readonly FIRST_NAME_FORM_CONTROL_NAME = 'firstName';
  public readonly LAST_NAME_FORM_CONTROL_NAME = 'lastName';
  public readonly EMAIL_FORM_CONTROL_NAME = 'email';
  public readonly PHONE_NUMBER_FORM_CONTROL_NAME = 'phoneNumber';
  public readonly PASSWORD_FORM_CONTROL_NAME = 'password';
  public readonly CONFIRM_PASSWORD_FORM_CONTROL_NAME = 'confirmPassword';
  public readonly ACCEPT_TERMS_FORM_CONTROL_NAME = 'acceptTerms';
  public readonly PROMOTION_FORM_CONTROL_NAME = 'promotion';

  public readonly patientRegistrationForm = this.formBuilder.group({
    [this.FIRST_NAME_FORM_CONTROL_NAME]: ['', Validators.required],
    [this.LAST_NAME_FORM_CONTROL_NAME]: ['', Validators.required],
    [this.EMAIL_FORM_CONTROL_NAME]: ['', { validators: [Validators.required, this.formValidatorsService.isEmailFirebaseCompliantValidator] }],
    [this.PHONE_NUMBER_FORM_CONTROL_NAME]: ['', { validators: [Validators.required, this.formValidatorsService.isPhoneNumberValidValidator(false)] }],
    [this.PASSWORD_FORM_CONTROL_NAME]: ['', { validators: [Validators.required, this.formValidatorsService.isPasswordValidValidator] }],
    [this.CONFIRM_PASSWORD_FORM_CONTROL_NAME]: ['', Validators.required],
    [this.PROMOTION_FORM_CONTROL_NAME]: [''],
    [this.ACCEPT_TERMS_FORM_CONTROL_NAME]: [false, Validators.requiredTrue],
    [this.PROMOTION_FORM_CONTROL_NAME]: [false],
  }, { validators: [this.formValidatorsService.controlValuesMatchingValidatorFactory(this.PASSWORD_FORM_CONTROL_NAME, this.CONFIRM_PASSWORD_FORM_CONTROL_NAME)] });

  public showSpinner = false;
  public isTiaHealth = false;

  @Output() registerButtonClicked = new EventEmitter();
  @Output() loginButtonClicked = new EventEmitter();

  ngOnInit(): void {
    try {
      const companyBookingRoute = this.bookingStepService.getActivatedRouteAncestorOfComponentType(this.activatedRoute, [CompanyBookingComponent]);
      const companyId = companyBookingRoute.snapshot.params.companyId;
      this.isTiaHealth = companyId === TIA_HEALTH_COMPANY_ID;
    } catch (error) {
      console.error(error);
    }
  }

  async handleRegisterButtonClick(): Promise<void> {
    if (this.patientRegistrationForm.invalid) {
      return;
    }

    this.showSpinner = true;
    try {
      const patientRegistrationData = this.getPatientRegistrationData(this.patientRegistrationForm);
      await this.patientRegistrationService.registerPatient(patientRegistrationData);

      this.registerButtonClicked.emit({
        email: this.patientRegistrationForm.controls[this.EMAIL_FORM_CONTROL_NAME].value,
        password: this.patientRegistrationForm.controls[this.PASSWORD_FORM_CONTROL_NAME].value,
      });
    } catch (error) {
      this.showSpinner = false;

      if (error instanceof HttpErrorResponse) {
        if (error.status === 405) {
          this.dialog.open(AwsWafCaptchaDialogComponent, AwsWafCaptchaDialogComponent.DEFAULT_CONFIG).afterClosed().subscribe((result) => {
            if (result) {
              this.handleRegisterButtonClick();
            }
          });
        } else {
          this.snackBar.open((error as HttpErrorResponse).error?.errorMessage, undefined, {
            duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS,
          });

          if (!(error as HttpErrorResponse).error?.errorCode || (error as HttpErrorResponse).error?.errorCode !== 'account-already-exists') {
            console.error(error);
            throw error;
          }
        }
      } else if (error instanceof Error) {
        this.snackBar.open(error.message, undefined, {
          duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS,
        });
      } else {
        this.snackBar.open('An unexpected error occurred', undefined, {
          duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS,
        });
        console.error(error);
        throw error;
      }
    }
  }

  handleLoginInsteadButtonClicked(): void {
    this.loginButtonClicked.emit();
  }

  getPatientRegistrationData(patientRegistrationForm: UntypedFormGroup): PatientRegistrationV2 {
    const patientRegistrationData: PatientRegistrationV2 = {
      firstName: patientRegistrationForm.controls[this.FIRST_NAME_FORM_CONTROL_NAME].value,
      lastName: patientRegistrationForm.controls[this.LAST_NAME_FORM_CONTROL_NAME].value,
      email: patientRegistrationForm.controls[this.EMAIL_FORM_CONTROL_NAME].value,
      phone: patientRegistrationForm.controls[this.PHONE_NUMBER_FORM_CONTROL_NAME].value,
      password: patientRegistrationForm.controls[this.PASSWORD_FORM_CONTROL_NAME].value,
      confirmPassword: patientRegistrationForm.controls[this.CONFIRM_PASSWORD_FORM_CONTROL_NAME].value,
      acceptTerms: patientRegistrationForm.controls[this.ACCEPT_TERMS_FORM_CONTROL_NAME].value,
      isExpresslyOptingInToPromotionalEmails: patientRegistrationForm.controls[this.PROMOTION_FORM_CONTROL_NAME].value,
    };

    if (patientRegistrationData.acceptTerms) {
      patientRegistrationData.lastAcceptedTermsOfUseVersion = PatientTermsOfUseComponent.VERSION;
    }

    return patientRegistrationData;
  }

  async openTermsAndConditions(): Promise<void> {
    const dialogRef = this.dialog.open(NewTiaPatientTermsDialogComponent, { autoFocus: false });

    const termsAndConditionsSelection = await firstValueFrom(dialogRef.afterClosed());

    const termsAndConditionsFormControl = this.patientRegistrationForm.controls[this.ACCEPT_TERMS_FORM_CONTROL_NAME];
    termsAndConditionsFormControl.setValue(termsAndConditionsSelection);
    termsAndConditionsFormControl.markAsTouched();
  }
}
