import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanActivateFn } from '@angular/router';
import { TIA_HEALTH_COMPANY_ID } from '../../constants';
import { IsAppropriateForVirtualCareDialogComponent } from '../../dialogs/is-appropriate-for-virtual-care/is-appropriate-for-virtual-care-dialog.component';
import { BookingStep, BookingStepService } from '../../services/booking-step/booking-step.service';

export const isAppropriateForVirtualCareGuard: CanActivateFn = async (activatedRouteSnapshot, routerStateSnapshot) => {
  const bookingStepService = inject(BookingStepService);
  const dialog = inject(MatDialog);

  const currentStep = await (async (): Promise<BookingStep> => bookingStepService.getStepFromUrl(routerStateSnapshot.url))().catch(() => undefined);
  if (activatedRouteSnapshot.params.companyId === TIA_HEALTH_COMPANY_ID && (currentStep === BookingStep.CHOOSE_DOCTOR || currentStep === undefined)) {
    const dialogRef = dialog.getDialogById(IsAppropriateForVirtualCareDialogComponent.DIALOG_ID);
    if (!dialogRef) {
      dialog.open(IsAppropriateForVirtualCareDialogComponent, IsAppropriateForVirtualCareDialogComponent.DIALOG_CONFIG);
    }
  }
  return true;
};
